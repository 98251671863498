
import { Options, Vue } from 'vue-class-component';
import { AuthClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import { LoginServices } from '@/services/LoginServices';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import store from '@/store';
import { CommonServices } from '@/services/CommonServices';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class ConfirmedCode extends Vue {
    
    code: string = "";
    identifier: string = ""

    created(){
        this.identifier = StorageServices.getUserIdentifier();
        if(!this.identifier)
            this.$router.push("/login");
    }

    sendCode(){
        AuthClient.confirm(this.identifier, this.code, store.state.preferredCulture)
        .then( x => {
            CommonServices.setAuthToken(x.token);
            var loginResponse = new OM.LoginResponse();
            loginResponse.token = x.token;
            loginResponse.userIdentifier = x.identifier;
            LoginServices.loginCallback(loginResponse, this);
        })
        .catch( error => {
            this.$opModal.show(ModalSuccessError, {
                isError: true,
                message: error.message, 
            })
        })
    }

    requestCode(){
        AuthClient.resetConfirmationCode(store.state.user.email)
        .then( x => {
            var message = LocalizationServices.getLocalizedValue("app_tiabbiamoinviatounamaildiconferma", "Ti abbiamo inviato una nuova email con il codice di conferma!")
            this.$opModal.show(ModalSuccessError, {
                message: message,
            })
        })
    }

}
